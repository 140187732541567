import IInquiry from '../models/IInquiry';
import { INQUIRY_STATUS_ENUM_VALUES } from '../api_enums/INQUIRY_STATUS_ENUM';
import { INQUIRY_PRIORITY_ENUM_VALUES } from '../api_enums/INQUIRY_PRIORITY_ENUM';
import IOrderPosition from '../models/IOrder';
import { ORDER_STATUS_ENUM_VALUES } from '../api_enums/ORDER_STATUS_ENUM';
import { ORDER_PRIORITY_ENUM_VALUES } from '../api_enums/ORDER_PRIORITY_ENUM';
import IDelivery from '../models/IDelivery';
import { DELIVERY_STATUS_ENUM_VALUES } from '../api_enums/DELIVERY_STATUS_ENUM';

export function sortInquiresByStatusPriorityId(a: IInquiry, b: IInquiry) {
  return (
    INQUIRY_STATUS_ENUM_VALUES.get(a.status).priority -
      INQUIRY_STATUS_ENUM_VALUES.get(b.status).priority ||
    INQUIRY_PRIORITY_ENUM_VALUES.get(a.priority).priority -
      INQUIRY_PRIORITY_ENUM_VALUES.get(b.priority).priority ||
    a.id - b.id
  );
}

export function sortOrderByStatusPriorityId(a: IInquiry, b: IInquiry) {
  return (
    INQUIRY_STATUS_ENUM_VALUES.get(a.status).priority_order -
      INQUIRY_STATUS_ENUM_VALUES.get(b.status).priority_order ||
    INQUIRY_PRIORITY_ENUM_VALUES.get(a.priority).priority -
      INQUIRY_PRIORITY_ENUM_VALUES.get(b.priority).priority ||
    a.id - b.id
  );
}

export function sortOrderPostionsByStatusPriorityId(
  a: IOrderPosition,
  b: IOrderPosition,
) {
  return (
    ORDER_STATUS_ENUM_VALUES.get(a.status).priority -
      ORDER_STATUS_ENUM_VALUES.get(b.status).priority ||
    ORDER_PRIORITY_ENUM_VALUES.get(a.priority).priority -
      ORDER_PRIORITY_ENUM_VALUES.get(b.priority).priority ||
    a.id - b.id
  );
}

export function sortDeliveriesByStatusId(a: IDelivery, b: IDelivery) {
  return (
    DELIVERY_STATUS_ENUM_VALUES.get(a.status).priority -
      DELIVERY_STATUS_ENUM_VALUES.get(b.status).priority || a.id - b.id
  );
}
